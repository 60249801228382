import { useEffect, useState } from 'react'
import { getSrc, onClickDownload } from '../lib'
import { LoadingSpinner } from '../loadingSpinner'
import './App.css'

const imgURLs = [
  // 01
  'f63f8dab-ccd8-4dc1-9d39-cb2a16f85178',

  // 02
  '11236ac5-5d14-4fa9-b1ed-d012fe5dcc49',

  // 03
  '87e3ef93-612d-4a95-be7e-1b4beae9cd3c',

  // logo
  '8f648e8d-5f85-44f6-a59c-349e07f707ec',

  // banner
  'fe1614e6-1053-4a98-9097-d1bf821f2d48',

  // footer
  '7ec93d1e-c2a1-4555-a07e-6ccf0a4aef83',
]

function Landing4(props: { dl: string | string[] }) {
  const [imgArr, setImgArr] = useState<string[]>([])
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    if (!props.dl.length) return
    const cdn = props.dl[0]
    const result: string[] = []

    async function getAllImg() {
      for (let i = 0; i < imgURLs.length; i++) {
        const res = await fetch(`https://${cdn}/images/${imgURLs[i]}`)
        const str = await res.text()
        result.push(getSrc(str))
      }
      setImgArr(result)
      setIsLoading(false)
    }
    getAllImg()
  }, [props.dl])

  if (imgArr.length < 0) return null

  return isLoading ? (
    <LoadingSpinner />
  ) : (
    <div className="App m-auto bg-[#001a40] md:max-w-[640px]">
      <div
        className="flex flex-col items-center justify-center"
        onClick={onClickDownload}>
        <div className="sticky top-0 z-10 flex w-full items-center justify-between bg-[#444] bg-gradient-to-r py-5 px-4">
          <img
            alt=""
            src={`data:image/gif;base64,${imgArr[3]}`}
            className="w-[30%]"
          />
          <div className="flex h-8 items-center justify-center rounded-2xl bg-white px-3 py-1 text-sm font-semibold text-[#e2519d]">
            立即下载
          </div>
        </div>
        <img
          alt=""
          className="w-full bg-[#444] px-4 pb-6"
          src={`data:image/gif;base64,${imgArr[4]}`}
        />
        <img
          alt=""
          className="w-full"
          src={`data:image/gif;base64,${imgArr[0]}`}
        />
        <img
          alt=""
          className="w-full"
          src={`data:image/gif;base64,${imgArr[1]}`}
        />
        <img
          alt=""
          className="w-full"
          src={`data:image/gif;base64,${imgArr[2]}`}
        />
        <img
          alt=""
          src={`data:image/gif;base64,${imgArr[5]}`}
          className="sticky bottom-[-4px] w-full bg-transparent"
        />
      </div>
    </div>
  )
}

export default Landing4
