
const TeachingButton = () => {
  return (
    <a 
  href="./img-setp.png" 
  target="_blank"
  rel="noopener noreferrer"
  className="cursor-pointer"
>
    <div className="relative flex items-center justify-center px-8 py-3">
      {/* Left Finger */}
      <div className="absolute left-2">
        <img src="./ic-handle.png" alt="" className="w-4 h-4" />
      </div>
      
      
      {/* Text */}
      <span className="text-lg font-bold text-white underline">
      苹果安装下载教学
      </span>
      
      {/* Right Finger */}
      <div className="absolute right-2">
        <img src="./ic-handle.png" alt="" className="scale-x-[-1] w-4 h-4" />
      </div>
    </div>
    </a>
  );
};

export default TeachingButton;