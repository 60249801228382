import { useEffect, useState } from 'react'
import { getSrc, onClickDownload } from '../lib'
import { LoadingSpinner } from '../loadingSpinner'

// p1
const imgURLs = [
  // 01
  'eef9db09-9809-4fb5-aca0-363bdcf21c7b',
  // 02
  '4130761b-50c3-4674-ad52-03a9b8d67aaf',
  // 03
  '7aedcdb7-c0ba-4f84-b310-b4f55b2ee711',
  // Footer
  'c20d8d3b-3dfd-4b6e-8439-a6baf40d6c8f',
]

function Landing3(props: { dl: string | string[] }) {
  const [imgArr, setImgArr] = useState<string[]>([])
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    if (!props.dl.length) return
    const cdn = props.dl[0]
    const result: string[] = []

    async function getAllImg() {
      for (let i = 0; i < imgURLs.length; i++) {
        const res = await fetch(`https://${cdn}/images/${imgURLs[i]}`)
        const str = await res.text()
        result.push(getSrc(str))
      }
      setImgArr(result)
      setIsLoading(false)
    }
    getAllImg()
  }, [props.dl])

  if (imgArr.length < 0) return null

  return isLoading ? (
    <LoadingSpinner />
  ) : (
    <div
      className="m-auto flex flex-col md:max-w-[640px]"
      onClick={onClickDownload}>
      <img
        alt=""
        src={`data:image/gif;base64,${imgArr[0]}`}
        className="w-full"
      />
      <a 
  href="./img-setp.png" 
  target="_blank"
  rel="noopener noreferrer"
  className="cursor-pointer"
>
      <img
        alt=""
        src={`data:image/gif;base64,${imgArr[1]}`}
        className="w-full"
      />
      </a>
      <img
        alt=""
        src={`data:image/gif;base64,${imgArr[2]}`}
        className="w-full"
      />
      {/* footer */}
      <img
        alt=""
        src={`data:image/gif;base64,${imgArr[3]}`}
        className="sticky bottom-0 w-full"
      />
    </div>
  )
}

export default Landing3
