import { useEffect, useState } from 'react'
import { getSrc, onClickDownload } from '../lib'
import { LoadingSpinner } from '../loadingSpinner'

// p2
const imgURLs = [
  // 01
  '2342e946-f5ca-446b-8cb2-672720608587',
  // 02
  '0889d8dd-dc0e-4c2a-9e7a-9739bcf3a960',
  // 03
  '6d86f311-66c8-48f1-a9b4-e6acf0be9b58',
  // Footer
  '3fa13a4f-25d3-4444-bd1a-1d8bcfe8aef4',
]

function Landing2(props: { dl: string | string[] }) {
  const [imgArr, setImgArr] = useState<string[]>([])
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    if (!props.dl.length) return
    const cdn = props.dl[0]
    const result: string[] = []

    async function getAllImg() {
      for (let i = 0; i < imgURLs.length; i++) {
        const res = await fetch(`https://${cdn}/images/${imgURLs[i]}`)
        const str = await res.text()
        result.push(getSrc(str))
      }
      setImgArr(result)
      setIsLoading(false)
    }
    getAllImg()
  }, [props.dl])

  if (imgArr.length < 0) return null

  return isLoading ? (
    <LoadingSpinner />
  ) : (
    <div
      className="m-auto flex flex-col md:max-w-[640px]"
      onClick={onClickDownload}>
      <img
        alt=""
        src={`data:image/gif;base64,${imgArr[0]}`}
        className="w-full"
      />
      <a 
  href="./img-setp.png" 
  target="_blank"
  rel="noopener noreferrer"
  className="cursor-pointer"
>
      <img
        alt=""
        src={`data:image/gif;base64,${imgArr[1]}`}
        className="w-full"
      />
      </a>
      <img
        alt=""
        src={`data:image/gif;base64,${imgArr[2]}`}
        className="w-full"
      />
      {/* footer */}
      <img
        alt=""
        src={`data:image/gif;base64,${imgArr[3]}`}
        className="sticky bottom-0 w-full"
      />
    </div>
  )
}

export default Landing2
