import React from 'react'
// @ts-ignore
import { createRoot } from 'react-dom/client'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import './index.css'
import App1 from './landing1'
import App2 from './landing2'
import App3 from './landing3'
import App4 from './landing4'
import { getStaticUrl } from './lib'

const container = document.getElementById('root')
const root = createRoot(container)

const devUrls = 'https://dev-wl-cn1-landing.hmtech-dev.com/'
const host = 
  // 檢查window.location.href是否包含'.html'，是的話就找出最後一個/的位置，並取出前面的字串
  window.location.href.indexOf('.html') > -1
    ? window.location.href.slice(0, window.location.href.lastIndexOf('/'))
    : // 檢查window.location.href的最後一個字元是否為'/'，是的話就去掉
    window.location.href.slice(-1) === '/'
    ? window.location.href.slice(0, -1)
    : window.location.href

const urls = [`${host}/dl.json`]
console.log('host', host)

Promise.all(urls.map((url) => fetch(url).then((res) => res.json()))).then(
  (res) => {
    const { dl } = res[0]
    console.log('res!!!', dl)

    var hostname = host
    var startIndex = hostname.indexOf('//') + 2
    var urlAfterDoubleSlash = hostname.substring(startIndex)
    var subdomain = urlAfterDoubleSlash.split('.')[0]

    getStaticUrl(host, subdomain).then((res) => {
      if (!res) return

      const script = document.createElement('script')
      script.innerHTML = `LA.init({id:'${res.id}',ck:'${res.ck}'})`
      document.head.appendChild(script)
    })

    root.render(
      <React.StrictMode>
        <BrowserRouter>
          <Routes>
            <Route path="/p1.html" element={<App1 dl={dl} />} />
            <Route path="/p2.html" element={<App2 dl={dl} />} />
            <Route path="/p3.html" element={<App3 dl={dl} />} />
            <Route path="/p4.html" element={<App4 dl={dl} />} />
            <Route path="*" element={<App1 dl={dl} />} />
          </Routes>
        </BrowserRouter>
      </React.StrictMode>
    )
  }
)
